import React from "react"
import styled, { css } from "styled-components"
import Image from "../components/image"
import SEO from "../components/seo"
import InstagramGrid from "../components/InstagramGrid"
import logo from "../images/dapper_logo.svg"

const layout = css`
  min-height: 100vh;
  width: 100%;
  background-color: #1b1b1b;

  ${InstagramGrid} {
    height: fit-content;
    width: fit-content;
    margin: 150px auto 100px auto;
  }
`

const CoverPhoto = styled(Image)`
  width: 100%;
`

const CoverWrapper = styled.div`
  height: fit-content;
  max-width: 1700px;
  margin: 50px auto 0 auto;

  @media only screen and (max-width: 800px) {
    margin-top: 100px;
  }

  @media only screen and (max-width: 600px) {
    margin-top: 150px;
  }
`

const Logo = styled.img`
  height: 100%;
  margin-top: 90px;

  @media only screen and (max-width: 600px) {
    height: 200px;
    margin-top: 120px;
  }
`

const LogoWrapper = styled.div`
  height: 300px;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 800px) {
    height: 250px;
  }

  @media only screen and (max-width: 600px) {
    height: 200px;
  }
`

const TagLine = styled.div`
  font-size: 20px;
  color: #ffc66d;
  margin: 100px auto 100px;
  letter-spacing: 4px;
  text-align: center;
  white-space: nowrap;

  @media only screen and (max-width: 1333px) {
    h1 {
      font-size: 30px;
    }
  }

  @media only screen and (max-width: 800px) {
    h1 {
      font-size: 40px;
    }
    margin: 120px auto;
  }
`

const Paragraph = styled.p`
  text-align: center;
  color: #ebebeb;
  font-size: 22px;
  width: 90%;
  max-width: 800px;
  margin: 100px auto;
  line-height: 1.5;
  letter-spacing: 1px;

  @media only screen and (max-width: 1333px) {
    font-size: 19px;
    max-width: 600px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 19px;
    width: 80%;
  }
`
class IndexPage extends React.Component {
  render() {
    const { mobile, className } = this.props
    return (
      <div className={className}>
        <SEO
          title="Home"
          keywords={[`dapper`, `conspiracy`, `the dapper conspiracy`]}
        />

        <LogoWrapper>
          <Logo src={logo} alt="the dapper conspiracy" />
        </LogoWrapper>

        <CoverWrapper>
          <CoverPhoto />
        </CoverWrapper>

        <TagLine>
          {mobile ? (
            <>
              <h1>STORYTELLERS</h1>
              <h3>ENTERTAINERS</h3>
              <h6>LIARS</h6>
            </>
          ) : (
            <h1>STORYTELLERS·ENTERTAINERS·LIARS</h1>
          )}
        </TagLine>

        <Paragraph>
          High energy, gritty Southern rock, infused with bluegrass, funk and
          blues they are storytellers who will burn the house down. Founded in
          Raleigh by lead singer and bassist Ben Byrom, they are inspired by the
          eerie gothic vibes of Fleetwood Mac, the virtuosity of Andrew Bird,
          and the creative energy of Spoon. Their tight but rowdy appeal shines
          in open air festivals and basement bars alike. Guaranteed to please.
        </Paragraph>
        <InstagramGrid />
      </div>
    )
  }
}

export default styled(IndexPage)`
  ${layout}
`

import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import axios from "axios"
import { instagramCacheIsExpired } from "../utils/time"

const IG_ENDPOINT = `https://api.instagram.com/v1/users/self/media/recent/?access_token=1392796270.75ac2e9.0800219a9fff4640ad69ee3f34f89bd7&count=9`

const layout = css`
  width: fit-content;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;

  img {
    margin: 0;
  }

  @media only screen and (max-width: 900px) {
    grid-gap: 20px;
  }

  @media only screen and (max-width: 600px) {
    grid-gap: 10px;
  }
`

const InstagramGrid = ({ className }) => {
  const [posts, setPosts] = useState([])

  useEffect(() => {
    let instagram = localStorage.getItem("dapper-IG")

    if (instagram && !instagramCacheIsExpired(instagram.expiration)) {
      instagram = JSON.parse(instagram)
      setPosts(instagram.posts)
      return
    }

    axios.get(IG_ENDPOINT).then(({ data }) => {
      setPosts(data.data)
      localStorage.setItem(
        "dapper-IG",
        JSON.stringify({
          expiration: Date.now(),
          posts: data.data,
        })
      )
    })
  }, [])

  return (
    <div className={className}>
      {posts.length &&
        posts.map(post => (
          <img
            key={post.id}
            src={post.images.low_resolution.url}
            alt={post.id}
          />
        ))}
    </div>
  )
}

export default styled(InstagramGrid)`
  ${layout}
`
